/*.phone {*/
/*    margin: 0 auto;*/
/*    position: relative;*/
/*    background: #111;*/
/*    border-radius: 55px;*/
/*    box-shadow: 0px 0px 0px 2px #aaa;*/
/*    width: 320px;*/
/*    height: 568px;*/
/*    padding: 105px 25px;*/
/*    -webkit-box-sizing: content-box;*/
/*    box-sizing: content-box;*/
/*}*/
/*.phone:before {*/
/*    content: '';*/
/*    width: 60px;*/
/*    height: 10px;*/
/*    border-radius: 10px;*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    margin-left: -30px;*/
/*    background: #333;*/
/*    top: 50px;*/
/*}*/
/*.phone:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    width: 60px;*/
/*    height: 60px;*/
/*    left: 50%;*/
/*    margin-left: -30px;*/
/*    bottom: 20px;*/
/*    border-radius: 100%;*/
/*    box-sizing: border-box;*/
/*    border: 5px solid #333;*/
/*}*/
.phone iframe {
    width: 100%;
    height: 100%;
    display: block;
    border-bottom-right-radius: 36px;
    border-bottom-left-radius: 36px;
}

.phone {
    position: relative;
    width: 382px;
    height: 807px;
    margin: 0 auto;
    padding: 81px 8px 7px;
    background: transparent url(https://gd-fe-assets.jinshujucdn.com/gd-frontend/media/phone_for_preview.d49c8330.png) no-repeat 0 0;
    background-size: 382px auto;
    -webkit-filter: drop-shadow(0 0 8px rgba(0,0,0,.175));
    filter: drop-shadow(0 0 8px rgba(0,0,0,.175));
}