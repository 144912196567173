.ant-pro-table .ant-card {
	background: transparent;
}
.ant-pro-table .ant-table-wrapper {
	/*background: white;*/
	/*box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);*/
	border: 1px solid #f0f0f0;
	background: #fff;
}

.ant-pro-table-toolbar {
	padding: 0;
	height: 48px;
}
.ant-pro-table-toolbar-title {
	display: none;
}
.ant-pro-table-toolbar-option {
	flex: 1;
}
.ant-pro-table-toolbar-option > .ant-space {
	flex: 1;
}
.ant-pro-table-toolbar-option > .ant-space > .ant-space-item:first-child {
	display: flex;
	flex: 1;
}
/* .ant-pro-table-toolbar-option .gstable-search-bar {
    display: flex;
    flex: 1;
}
.ant-pro-table-toolbar-option .gstable-operations-bar {
    flex: 1;
} */
/*
.ant-pro-table-toolbar-default-option .ant-divider {
    display: none;
}
*/
