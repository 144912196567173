body {
	font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Tahoma, 'PingFang SC', 'Microsoft YaHei', Arial,
		'Hiragino Sans GB', sans-serif;
	font-size: 13px;
	line-height: 1.53846154;
	color: #141414;
	background-color: #f0f2f5;
}
body{
	width: 100%;
	height: 100%;
}
#root{
	width: 100%;
	height: 100%
}
.w-e-text-container {
	z-index: 100 !important;
}
.w-e-menu {

	z-index: 101 !important;
}

.center{
	display: flex;
	justify-content: center;
}
.sider-menu-logo {
	color: #fff;
	padding: 0 25px;
	overflow: hidden;
	height: 48px;
	line-height: 48px;
}
.sider-menu-logo a {
	color: white;
	font-size: 14px;
}

.modal-body table {
	padding-left: 45%;
}
.modal-body table tbody tr {
	margin-top: 20px;
}
.modal-body table tbody tr th {
	color: #999;
	float: right;
}
h6 {
	text-align: center;
}

#components-dropdown-demo-placement .ant-btn {
	margin-right: 8px;
	margin-bottom: 8px;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
	height: 50px;
	line-height: 50px;
}
/* 二级菜单 */
.submenu {
	/*background: #e5e5e5;*/
	border-radius: 0px;
	margin-bottom: 0px;
	/*padding-bottom: 10px;*/
	border-width: 0;
	background: white;
}

.submenu .nav-secondary > li > a:hover {
	color: #0288d1;
	background: #f1f1f1;
	border-bottom: 2px solid #0288d1;
}
.submenu .nav-secondary > li > a:focus {
	color: #0288d1;
	/*border-bottom: 2px solid #0288d1;*/
	/*background: #F1F1F1;*/
}
.submenu .nav-secondary > li > .active {
	color: #0288d1;
	border-bottom: 2px solid #0288d1;
}

.nav-secondary > li > a {
	border: 0px;
}
.nav-secondary {
	background: white;
	padding-left: 15px;
}

.navbar-default {
	background-color: #f0f2f5;
}

.navbar {
	margin: 0px;
}

/* 表格数据筛选查询操作bar */
.filter-menu-bar {
	/*background-color: #f8fafe;*/
	padding: 5px 0px;
	/* margin-bottom: 10px;*/
	/*    border-bottom: 1px solid lightgrey;*/
}
.filter-menu-bar.navbar {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}
.filter-menu-bar .navbar-default {
	border-left: 0 !important;
	border-right: 0 !important;
	border-top: 0 !important;
}
.filter-menu-bar > nav > li.active {
}
.filter-menu-bar ul > li > a {
	padding-bottom: 6px;
	padding-top: 6px;
}
.filter-menu-bar ul > li {
}
.filter-menu-bar .navbar-right {
	margin-right: 0px;
}
.filter-menu-bar .pull-right li {
	list-style: none;
	height: 32px;
	float: left;
}
.filter-menu-bar .pull-right .btn-primary {
	height: 32px;
}
.filter-menu-bar .pull-right {
	margin-bottom: 0px;
	padding-right: 15px;
}
.filter-menu-bar .input-group {
	width: 250px;
	padding-left: 15px;
}
.filter-menu-bar .breadcrumb {
	margin-bottom: 0px;
	padding-top: 2px;
	padding-bottom: 6px;
}
.filter-menu-bar .input-group-first {
	padding-left: 15px;
}
.filter-menu-bar .input-group-first input {
	width: 100%;
}
.filter-menu-bar .input-group-one {
	padding-left: 15px;
}
.filter-menu-bar .input-group-one input {
	width: 100%;
}
.filter-menu-bar .input-groups {
	padding-left: 15px;
}
.filter-menu-bar .query-conditions-first {
	margin-left: 15px;
}
.filter-menu-bar .query-conditions-one {
	margin-left: 5px;
}
.filter-menu-bar .operation-one {
	padding-left: 5px;
}
.filter-menu-bar input {
	/*height: 32px;*/
	padding: 5px 8px;
	/*font-size: 13px;*/
	line-height: 1.53846154;
	color: #222;
	vertical-align: middle;
	background-color: #fff;
	/*border: 1px solid #ccc;*/
	border-radius: 4px;
}

.content-big {
	/*    padding: 10px;*/
	padding: 15px 15px;
	width: 100%;
	height: 100%;
	min-width: 940px;
	background: #f0f2f5;
}

.nav-tabs {
	margin: 15px 0;
}

.devices-list {
	padding: 10px;
}
.hendel {
	display: none !important;
}
.QRCodeA:hover {
	cursor: pointer;
}
.clickItem:hover {
	cursor: pointer;
}

.table {
	word-break: break-all !important;
}
.ulli {
	padding-left: 20px;
}
.ulli li {
	padding-right: 20px;
}
.TextDecora {
	text-decoration: none !important;
}

.meta-style {
	padding: 0px !important;
	/*display: flex;*/
	/*justify-content: center;*/
	/*align-items: center;*/
	height: 93px;
}
.ant-card-actions > li {
	margin: 5px 0px !important;
}

.steps-content {
	margin-top: 16px;
	border: 1px dashed #e9e9e9;
	border-radius: 6px;
	background-color: #fafafa;
	/*min-height: 200px;*/
	padding: 20px;
}

.steps-action {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
}
.room-label {
	font-weight: 500;
	font-size: 14px;
	color: #222222;
}
.ant-row {
	margin-top: 10px;
}

#meetingDetail-title-list {
	margin-top: 5px;
	margin-bottom: 10px;
	background: white;
}

#meetingDetail-title-list a {
	color: white;
}

#meetingDetail-title-list a:hover {
	color: white;
	background: #58b0ff;
}

#meetingDetail-title-list > li {
	float: left;
	margin-left: 0px;
	list-style-type: none;
	padding: 8px;
	font-weight: bold;
	background: #1890ff;
}

#meetingDetail-title-list > li:hover {
	background: #58b0ff;
}

.reportForm-ul > li {
	float: left;
	margin-left: 20px;
	list-style-type: none;
	color: white;
}

#meetingInfo-table {
	width: 100%;
	font-size: 13px;
}

#meetingInfo-table > tr {
	border-bottom: #dcdcdc solid 1px;
}

#meetingInfo-table > tr > td {
	padding-top: 19px;
}

.day-chose {
	overflow-y: hidden;
	overflow-x: auto;
	white-space: nowrap;
	width: 100%;
	margin: 0 auto;
	background: white;
	border-radius: 10px;
}

.scroll-days {
	width: 200px;
}

/*.scroll-days:hover {*/
/*    background: #e7e7e7;*/
/*}*/

.scroll-days > p {
	text-align: center;
}

/*.chosen-date {*/
/*    color: red;*/
/*    border-bottom: red solid 2px;*/
/*}*/

.orderTime-date-li {
	background: white;
	float: left;
	width: 33.3%;
	list-style-type: none;
	text-align: center;
	padding: 12px 0;
	border: #ac9a7c solid 1px;
}

.meeting-imgs-small {
	position: relative;
	margin-left: 30px;
	margin-top: 46px;
	float: left;
	height: 300px;
	margin-bottom: 30px;
	width: 200px;
}

.meeting-img-one {
	/*position: absolute;*/
	z-index: 1;
	/*display: inline-block;*/
}

.cover-img {
	z-index: 999;
	position: absolute;
	left: 0;
}

.meeting-img-nocover {
	z-index: 1;
}

.imgs-word {
	width: 100%;
	height: 36px;
	left: 0;
	border-bottom: 1px dashed black;
	color: white;
	top: 87%;
	position: absolute;
}

.first-img {
	height: 100%;
	float: left;
	width: 50%;
	text-align: center;
	border-right: 1px dashed black;
	border-left: 1px dashed black;
	font-size: 20px;
}

.delete-img-btn {
	height: 100%;
	float: left;
	width: 50%;
	text-align: center;
	font-size: 20px;
	border-right: 1px dashed black;
}

.meeting-imgs-imgbtn {
	background: #f1a325;
	font-size: 13px;
	padding: 6px 8px;
	border: none;
	border-radius: 3px;
}

.meeting-imgs-imgbtn:hover {
	background: #d79222;
}

.table-bordered {
	width: 100%;
}

.table-bordered td {
	border: 1px solid #ddd;
	padding: 7px;
}

.table-line td {
	border-left: white solid 0px;
	border-right: white solid 0px;
}

.company-table td {
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.table-bordered th {
	border: 1px solid #ddd;
	padding: 7px;
	background-color: #f1f1f1;
}

.table-line th {
	border-left: white solid 0px;
	border-right: white solid 0px;
}

.qiyeguanli-button {
	background: #0288d1;
	color: white;
	width: 80px;
	margin-bottom: 25px;
	margin-left: 30px;
}

.qiyeguanli-button-clean {
	width: 80px;
	margin-bottom: 30px;
	margin-right: 2px;
	margin-left: 130px;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid #ddd;
}

.list-group-item > i {
	font-style: normal;
}
.ant-card  .ant-card-body{
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-top: 20px;
}
.ant-pro-table .ant-card-body {
	padding-left: 0;
	padding-right: 0;
	/*padding-bottom: 20px;*/
	/*padding-top: 20px;*/
}

/*.ant-tag { !*64 x 32*!*/
/*    width: 64px;*/
/*    height: 32px;*/
/*    text-align: center;*/
/*    padding-top: 5px;*/
/*    font-size: 16px;*/
/*}*/

#householdList td,
#householdList th {
	padding-left: 15px;
}

.ant-list-item-action {
	margin-left: 0;
}

.a-disable {
	pointer-events: none;
	color: #9b9b9b;
}
/*.ant-tag {*/
/*    width: 64px;*/
/*    height: 32px;*/
/*    text-align: center;*/
/*    padding-top: 5px;*/
/*    font-size: 14px;*/
/*}*/
.ant-tree-switcher {
	position: relative;
	-ms-flex: none;
	flex: none;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	width: 24px;
	margin: 0;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.margin-top--10 {
	margin-top: -10px;
}
.ant-upload.ant-upload-drag {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #fff;
	border: 1px dashed #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	-webkit-transition: border-color 0.3s;
	transition: border-color 0.3s;
}

.avatar-uploader1 > .ant-upload {
	width: 130px;
	height: 130px;
}
.tooltip-width{
	width: 400px;
}
.ant-tooltip {
	max-width: 500px;
}

img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.img-auto{
	width: 100%;
	height: auto;
	margin: 0 auto;
	max-width: 100%;
	max-height: 180px;
}

.w-e-menu {
	z-index: auto !important;
}
.w-e-toolbar {
	z-index: 1 !important;
}
.w-e-text-container{
	z-index: 0 !important;
}

.w-e-droplist {
	z-index: 3 !important;
}


tr.drop-over-downward td {
	border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
	border-top: 2px dashed #1890ff;
}
.d-flex{
	display: flex;
}
.flex-column{
	flex-direction: column;
}
.align-content-center{
	align-content:center
}
.align-items-center{
	align-items: center;
}
.align-items-end{
	align-items: flex-end;
}
.text-center{
	text-align: center;
}
.justify-content-center{
	justify-content: center;
}
.justify-content-start{
	justify-content: flex-start;
}
.justify-content-end{
	justify-content: flex-end;
}
.justify-content-between{
	justify-content: space-between;
}
.flex-between-center{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.selectZiduan {
	border: 1px;
	border-color: #b3b3b3;
	border-style: dashed;
	background-color: rgba(244,244,244,0.7);

}

.imgbox{
	display: -webkit-flex;
	/*width: 50px;*/
	height: 60px;
	padding: 10px;
	box-sizing: border-box;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	justify-content: center;
}
span {
	word-break: break-word;
}
.omit1{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

.ant-form-item-tooltip {
	cursor: pointer;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	padding: 20px;
}
.single-line-text {
	display: inline-block;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow:ellipsis;
}

/* 票据 */
/* height: 480px; */
.piaoju .main{width: 960px;  margin: 0px auto; margin-top: 20px; padding: 10px; display: flex;}
.piaoju .body{width: 100%; }
.piaoju .title{width: 100%; height: 40px; display: flex;}
/* .piaoju .title .div1{width: 60%; text-align: right;}
.piaoju .title .div1 .divCtn{width: 230px; height: 40px; float: right;}
.piaoju .title .div1 .divCtn .titleCtn{width: 230px; height: 30px; line-height: 30px;text-align: center; font-size: 25px; font-weight: bold;}
.piaoju .title .div1 .divCtn .titleUdeLine{width: 230px; height: 6px; border-bottom: solid 2px #9C5223; border-top: solid 2px #9C5223;}
.piaoju .title .div2{width: 40%; text-align: right; height: 40px; line-height: 30px; font-size: 20px; font-weight: bold;}
.piaoju .date{width: 100%; height: 40px; display: flex;}
.piaoju .date .deDiv1{width: 60%; height: 40px; line-height: 40px; text-align: right; font-size: 18px;}
.piaoju .date .deDiv2{width: 40%; height: 40px;line-height: 40px;  text-align: right;font-size: 18px;}
.piaoju .tempTr1 td{text-align: center;}
.piaoju table tr td{padding: 5px;}
.piaoju .tempTr2 td{height: 40px; text-align: center;}
.piaoju .end{width: 5%; height: 480px; } */
/* 票据 */


