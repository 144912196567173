/* @import '~antd/dist/antd.dark.css'; */
@import './pulbic.css';


  .dark-bg .ant-drawer tr.ant-table-expanded-row > td,
  .dark-bg .ant-drawer tr.ant-table-expanded-row:hover > td {
	background: #272727;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-small thead > tr > th {
	background-color: #1f1f1f;
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-drawer .ant-table .ant-table-row-expand-icon {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table tfoot > tr > th,
  .dark-bg .ant-drawer .ant-table tfoot > tr > td {
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table thead > tr > th {
	background-color: #272727;
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table tbody > tr > td {
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .dark-bg .ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-right {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-drawer .ant-table tbody > tr.ant-table-row:hover > td {
	background: #303030;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered .ant-table-title {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered thead > tr > th,
  .dark-bg .ant-drawer .ant-table.ant-table-bordered tbody > tr > td,
  .dark-bg .ant-drawer .ant-table.ant-table-bordered tfoot > tr > th,
  .dark-bg .ant-drawer .ant-table.ant-table-bordered tfoot > tr > td {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered .ant-table-container {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table.ant-table-bordered .ant-table-footer {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-drawer .ant-table .ant-table-filter-trigger-container-open {
	background-color: #525252;
  }
  .dark-bg .ant-modal tr.ant-table-expanded-row > td,
  .dark-bg .ant-modal tr.ant-table-expanded-row:hover > td {
	background: #272727;
  }
  .dark-bg .ant-modal .ant-table.ant-table-small thead > tr > th {
	background-color: #1f1f1f;
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-modal .ant-table .ant-table-row-expand-icon {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table tfoot > tr > th,
  .dark-bg .ant-modal .ant-table tfoot > tr > td {
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table thead > tr > th {
	background-color: #272727;
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table tbody > tr > td {
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .dark-bg .ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-right {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-modal .ant-table tbody > tr.ant-table-row:hover > td {
	background: #303030;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered .ant-table-title {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered thead > tr > th,
  .dark-bg .ant-modal .ant-table.ant-table-bordered tbody > tr > td,
  .dark-bg .ant-modal .ant-table.ant-table-bordered tfoot > tr > th,
  .dark-bg .ant-modal .ant-table.ant-table-bordered tfoot > tr > td {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered .ant-table-container {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table.ant-table-bordered .ant-table-footer {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-modal .ant-table .ant-table-filter-trigger-container-open {
	background-color: #525252;
  }
  .dark-bg .ant-notification tr.ant-table-expanded-row > td,
  .dark-bg .ant-notification tr.ant-table-expanded-row:hover > td {
	background: #272727;
  }
  .dark-bg .ant-notification .ant-table.ant-table-small thead > tr > th {
	background-color: #1f1f1f;
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-notification .ant-table .ant-table-row-expand-icon {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table tfoot > tr > th,
  .dark-bg .ant-notification .ant-table tfoot > tr > td {
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table thead > tr > th {
	background-color: #272727;
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table tbody > tr > td {
	border-bottom: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .dark-bg .ant-notification .ant-table tbody > tr > td.ant-table-cell-fix-right {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-notification .ant-table tbody > tr.ant-table-row:hover > td {
	background: #303030;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered .ant-table-title {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered thead > tr > th,
  .dark-bg .ant-notification .ant-table.ant-table-bordered tbody > tr > td,
  .dark-bg .ant-notification .ant-table.ant-table-bordered tfoot > tr > th,
  .dark-bg .ant-notification .ant-table.ant-table-bordered tfoot > tr > td {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered .ant-table-container {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
	border-right: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table.ant-table-bordered .ant-table-footer {
	border: 1px solid #3a3a3a;
  }
  .dark-bg .ant-notification .ant-table .ant-table-filter-trigger-container-open {
	background-color: #525252;
  }
  .dark-bg .ant-table.ant-table-middle {
	font-size: 14px;
  }
  .dark-bg .ant-table.ant-table-middle .ant-table-title,
  .dark-bg .ant-table.ant-table-middle .ant-table-footer,
  .dark-bg .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .dark-bg .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .dark-bg .ant-table.ant-table-middle tfoot > tr > th,
  .dark-bg .ant-table.ant-table-middle tfoot > tr > td {
	padding: 12px 8px;
  }
  .dark-bg .ant-table.ant-table-middle .ant-table-filter-trigger {
	margin-right: -4px;
  }
  .dark-bg .ant-table.ant-table-middle .ant-table-expanded-row-fixed {
	margin: -12px -8px;
  }
  .dark-bg .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
	margin: -12px -8px -12px 25px;
  }
  .dark-bg .ant-table.ant-table-small {
	font-size: 14px;
  }
  .dark-bg .ant-table.ant-table-small .ant-table-title,
  .dark-bg .ant-table.ant-table-small .ant-table-footer,
  .dark-bg .ant-table.ant-table-small .ant-table-thead > tr > th,
  .dark-bg .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .dark-bg .ant-table.ant-table-small tfoot > tr > th,
  .dark-bg .ant-table.ant-table-small tfoot > tr > td {
	padding: 8px 8px;
  }
  .dark-bg .ant-table.ant-table-small .ant-table-filter-trigger {
	margin-right: -4px;
  }
  .dark-bg .ant-table.ant-table-small .ant-table-expanded-row-fixed {
	margin: -8px -8px;
  }
  .dark-bg .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
	margin: -8px -8px -8px 25px;
  }
  .dark-bg .ant-table-small .ant-table-thead > tr > th {
	background-color: #1d1d1d;
  }
  .dark-bg .ant-table-small .ant-table-selection-column {
	width: 46px;
	min-width: 46px;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-title {
	border: 1px solid #303030;
	border-bottom: 0;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container {
	border-left: 1px solid #303030;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
	border-right: 1px solid #303030;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
	background-color: transparent !important;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
	border-right: 1px solid #303030;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
	margin: -16px -17px;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
	position: absolute;
	top: 0;
	right: 1px;
	bottom: 0;
	border-right: 1px solid #303030;
	content: '';
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
  .dark-bg .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
	border-top: 1px solid #303030;
  }
  .dark-bg .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
  .dark-bg .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
	border-right: 0;
  }
  .dark-bg .ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .dark-bg .ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
	margin: -12px -9px;
  }
  .dark-bg .ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .dark-bg .ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
	margin: -8px -9px;
  }
  .dark-bg .ant-table.ant-table-bordered > .ant-table-footer {
	border: 1px solid #303030;
	border-top: 0;
  }
  .dark-bg .ant-table-cell .ant-table-container:first-child {
	border-top: 0;
  }
  .dark-bg .ant-table-cell-scrollbar {
	box-shadow: 0 1px 0 1px #1d1d1d;
  }
  .dark-bg .ant-table-wrapper {
	clear: both;
	max-width: 100%;
  }
  .dark-bg .ant-table-wrapper::before {
	display: table;
	content: '';
  }
  .dark-bg .ant-table-wrapper::after {
	display: table;
	clear: both;
	content: '';
  }
  .dark-bg .ant-table {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(255, 255, 255, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	font-size: 14px;
	background: #141414;
	border-radius: 2px;
  }
  .dark-bg .ant-table table {
	width: 100%;
	text-align: left;
	border-radius: 2px 2px 0 0;
	border-collapse: separate;
	border-spacing: 0;
  }
  .dark-bg .ant-table-thead > tr > th,
  .dark-bg .ant-table-tbody > tr > td,
  .dark-bg .ant-table tfoot > tr > th,
  .dark-bg .ant-table tfoot > tr > td {
	position: relative;
	padding: 16px 16px;
	overflow-wrap: break-word;
  }
  .dark-bg .ant-table-cell-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: keep-all;
  }
  .dark-bg .ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
  .dark-bg .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
	overflow: visible;
  }
  .dark-bg .ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
  .dark-bg .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  .dark-bg .ant-table-cell-ellipsis .ant-table-column-title {
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: keep-all;
  }
  .dark-bg .ant-table-title {
	padding: 16px 16px;
  }
  .dark-bg .ant-table-footer {
	padding: 16px 16px;
	color: rgba(255, 255, 255, 0.85);
	background: rgba(255, 255, 255, 0.04);
  }
  .dark-bg .ant-table-thead > tr > th {
	position: relative;
	color: rgba(255, 255, 255, 0.85);
	font-weight: 500;
	text-align: left;
	background: #1d1d1d;
	border-bottom: 1px solid #303030;
	transition: background 0.3s ease;
  }
  .dark-bg .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
	text-align: center;
  }
  .dark-bg .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 1.6em;
	background-color: rgba(255, 255, 255, 0.08);
	transform: translateY(-50%);
	transition: background-color 0.3s;
	content: '';
  }
  .dark-bg .ant-table-thead > tr:not(:last-child) > th[colspan] {
	border-bottom: 0;
  }
  .dark-bg .ant-table-tbody > tr > td {
	border-bottom: 1px solid #303030;
	transition: background 0.3s;
  }
  .dark-bg .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
  .dark-bg .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
	margin: -16px -16px -16px 33px;
  }
  .dark-bg .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
  .dark-bg .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
	border-bottom: 0;
  }
  .dark-bg .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
  .dark-bg .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
  .dark-bg .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
  .dark-bg .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
	border-radius: 0;
  }
  .dark-bg .ant-table-tbody > tr.ant-table-row:hover > td,
  .dark-bg .ant-table-tbody > tr > td.ant-table-cell-row-hover {
	background: #262626;
  }
  .dark-bg .ant-table-tbody > tr.ant-table-row-selected > td {
	background: #111b26;
	border-color: rgba(0, 0, 0, 0.03);
  }
  .dark-bg .ant-table-tbody > tr.ant-table-row-selected:hover > td {
	background: #0e161f;
  }
  .dark-bg .ant-table-summary {
	position: relative;
	z-index: 2;
	background: #141414;
  }
  .dark-bg div.ant-table-summary {
	box-shadow: 0 -1px 0 #303030;
  }
  .dark-bg .ant-table-summary > tr > th,
  .dark-bg .ant-table-summary > tr > td {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-table-pagination.ant-pagination {
	margin: 16px 0;
  }
  .dark-bg .ant-table-pagination {
	display: flex;
	flex-wrap: wrap;
	row-gap: 8px;
  }
  .dark-bg .ant-table-pagination > * {
	flex: none;
  }
  .dark-bg .ant-table-pagination-left {
	justify-content: flex-start;
  }
  .dark-bg .ant-table-pagination-center {
	justify-content: center;
  }
  .dark-bg .ant-table-pagination-right {
	justify-content: flex-end;
  }
  .dark-bg .ant-table-thead th.ant-table-column-has-sorters {
	cursor: pointer;
	transition: all 0.3s;
  }
  .dark-bg .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: #303030;
  }
  .dark-bg .ant-table-thead th.ant-table-column-has-sorters:hover::before {
	background-color: transparent !important;
  }
  .dark-bg .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
  .dark-bg .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
	background: #222;
  }
  .dark-bg .ant-table-thead th.ant-table-column-sort {
	background: #262626;
  }
  .dark-bg .ant-table-thead th.ant-table-column-sort::before {
	background-color: transparent !important;
  }
  .dark-bg td.ant-table-column-sort {
	background: rgba(255, 255, 255, 0.01);
  }
  .dark-bg .ant-table-column-title {
	position: relative;
	z-index: 1;
	flex: 1;
  }
  .dark-bg .ant-table-column-sorters {
	display: flex;
	flex: auto;
	align-items: center;
	justify-content: space-between;
  }
  .dark-bg .ant-table-column-sorters::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
  }
  .dark-bg .ant-table-column-sorter {
	margin-left: 4px;
	color: #bfbfbf;
	font-size: 0;
	transition: color 0.3s;
  }
  .dark-bg .ant-table-column-sorter-inner {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
  }
  .dark-bg .ant-table-column-sorter-up,
  .dark-bg .ant-table-column-sorter-down {
	font-size: 11px;
  }
  .dark-bg .ant-table-column-sorter-up.active,
  .dark-bg .ant-table-column-sorter-down.active {
	color: #177ddc;
  }
  .dark-bg .ant-table-column-sorter-up + .ant-table-column-sorter-down {
	margin-top: -0.3em;
  }
  .dark-bg .ant-table-column-sorters:hover .ant-table-column-sorter {
	color: #a6a6a6;
  }
  .dark-bg .ant-table-filter-column {
	display: flex;
	justify-content: space-between;
  }
  .dark-bg .ant-table-filter-trigger {
	position: relative;
	display: flex;
	align-items: center;
	margin: -4px -8px -4px 4px;
	padding: 0 4px;
	color: #bfbfbf;
	font-size: 12px;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s;
  }
  .dark-bg .ant-table-filter-trigger:hover {
	color: rgba(255, 255, 255, 0.45);
	background: #434343;
  }
  .dark-bg .ant-table-filter-trigger.active {
	color: #177ddc;
  }
  .dark-bg .ant-table-filter-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(255, 255, 255, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	min-width: 120px;
	background-color: #1f1f1f;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .dark-bg .ant-table-filter-dropdown .ant-dropdown-menu {
	max-height: 264px;
	overflow-x: hidden;
	border: 0;
	box-shadow: none;
  }
  .dark-bg .ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
	display: block;
	padding: 8px 0;
	color: rgba(255, 255, 255, 0.3);
	font-size: 12px;
	text-align: center;
	content: 'Not Found';
  }
  .dark-bg .ant-table-filter-dropdown-tree {
	padding: 8px 8px 0;
  }
  .dark-bg .ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
	background-color: rgba(255, 255, 255, 0.08);
  }
  .dark-bg .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
  .dark-bg .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
	background-color: #11263c;
  }
  .dark-bg .ant-table-filter-dropdown-search {
	padding: 8px;
	border-bottom: 1px #303030 solid;
  }
  .dark-bg .ant-table-filter-dropdown-search-input input {
	min-width: 140px;
  }
  .dark-bg .ant-table-filter-dropdown-search-input .anticon {
	color: rgba(255, 255, 255, 0.3);
  }
  .dark-bg .ant-table-filter-dropdown-checkall {
	width: 100%;
	margin-bottom: 4px;
	margin-left: 4px;
  }
  .dark-bg .ant-table-filter-dropdown-submenu > ul {
	max-height: calc(-30vh);
	overflow-x: hidden;
	overflow-y: auto;
  }
  .dark-bg .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .dark-bg .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
	padding-left: 8px;
  }
  .dark-bg .ant-table-filter-dropdown-btns {
	display: flex;
	justify-content: space-between;
	padding: 7px 8px;
	overflow: hidden;
	background-color: #1f1f1f;
	border-top: 1px solid #303030;
  }
  .dark-bg .ant-table-selection-col {
	width: 32px;
  }
  .dark-bg .ant-table-bordered .ant-table-selection-col {
	width: 50px;
  }
  .dark-bg table tr th.ant-table-selection-column,
  .dark-bg table tr td.ant-table-selection-column {
	padding-right: 8px;
	padding-left: 8px;
	text-align: center;
  }
  .dark-bg table tr th.ant-table-selection-column .ant-radio-wrapper,
  .dark-bg table tr td.ant-table-selection-column .ant-radio-wrapper {
	margin-right: 0;
  }
  .dark-bg table tr th.ant-table-selection-column.ant-table-cell-fix-left {
	z-index: 3;
  }
  .dark-bg table tr th.ant-table-selection-column::after {
	background-color: transparent !important;
  }
  .dark-bg .ant-table-selection {
	position: relative;
	display: inline-flex;
	flex-direction: column;
  }
  .dark-bg .ant-table-selection-extra {
	position: absolute;
	top: 0;
	z-index: 1;
	cursor: pointer;
	transition: all 0.3s;
	-webkit-margin-start: 100%;
	margin-inline-start: 100%;
	-webkit-padding-start: 4px;
	padding-inline-start: 4px;
  }
  .dark-bg .ant-table-selection-extra .anticon {
	color: #bfbfbf;
	font-size: 10px;
  }
  .dark-bg .ant-table-selection-extra .anticon:hover {
	color: #a6a6a6;
  }
  .dark-bg .ant-table-expand-icon-col {
	width: 48px;
  }
  .dark-bg .ant-table-row-expand-icon-cell {
	text-align: center;
  }
  .dark-bg .ant-table-row-indent {
	float: left;
	height: 1px;
  }
  .dark-bg .ant-table-row-expand-icon {
	color: #177ddc;
	text-decoration: none;
	cursor: pointer;
	transition: color 0.3s;
	position: relative;
	display: inline-flex;
	float: left;
	box-sizing: border-box;
	width: 17px;
	height: 17px;
	padding: 0;
	color: inherit;
	line-height: 17px;
	background: transparent;
	border: 1px solid #303030;
	border-radius: 2px;
	outline: none;
	transform: scale(0.94117647);
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  .dark-bg .ant-table-row-expand-icon:focus,
  .dark-bg .ant-table-row-expand-icon:hover {
	color: #165996;
  }
  .dark-bg .ant-table-row-expand-icon:active {
	color: #388ed3;
  }
  .dark-bg .ant-table-row-expand-icon:focus,
  .dark-bg .ant-table-row-expand-icon:hover,
  .dark-bg .ant-table-row-expand-icon:active {
	border-color: currentColor;
  }
  .dark-bg .ant-table-row-expand-icon::before,
  .dark-bg .ant-table-row-expand-icon::after {
	position: absolute;
	background: currentColor;
	transition: transform 0.3s ease-out;
	content: '';
  }
  .dark-bg .ant-table-row-expand-icon::before {
	top: 7px;
	right: 3px;
	left: 3px;
	height: 1px;
  }
  .dark-bg .ant-table-row-expand-icon::after {
	top: 3px;
	bottom: 3px;
	left: 7px;
	width: 1px;
	transform: rotate(90deg);
  }
  .dark-bg .ant-table-row-expand-icon-collapsed::before {
	transform: rotate(-180deg);
  }
  .dark-bg .ant-table-row-expand-icon-collapsed::after {
	transform: rotate(0deg);
  }
  .dark-bg .ant-table-row-expand-icon-spaced {
	background: transparent;
	border: 0;
	visibility: hidden;
  }
  .dark-bg .ant-table-row-expand-icon-spaced::before,
  .dark-bg .ant-table-row-expand-icon-spaced::after {
	display: none;
	content: none;
  }
  .dark-bg .ant-table-row-indent + .ant-table-row-expand-icon {
	margin-top: 2.5005px;
	margin-right: 8px;
  }
  .dark-bg tr.ant-table-expanded-row > td,
  .dark-bg tr.ant-table-expanded-row:hover > td {
	background: #1d1d1d;
  }
  .dark-bg tr.ant-table-expanded-row .ant-descriptions-view {
	display: flex;
  }
  .dark-bg tr.ant-table-expanded-row .ant-descriptions-view table {
	flex: auto;
	width: auto;
  }
  .dark-bg .ant-table .ant-table-expanded-row-fixed {
	position: relative;
	margin: -16px -16px;
	padding: 16px 16px;
  }
  .dark-bg .ant-table-tbody > tr.ant-table-placeholder {
	text-align: center;
  }
  .dark-bg .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
	color: rgba(255, 255, 255, 0.3);
  }
  .dark-bg .ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background: #141414;
  }
  .dark-bg .ant-table-cell-fix-left,
  .dark-bg .ant-table-cell-fix-right {
	position: -webkit-sticky !important;
	position: sticky !important;
	z-index: 2;
	background: #141414;
  }
  .dark-bg .ant-table-cell-fix-left-first::after,
  .dark-bg .ant-table-cell-fix-left-last::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: -1px;
	width: 30px;
	transform: translateX(100%);
	transition: box-shadow 0.3s;
	content: '';
	pointer-events: none;
  }
  .dark-bg .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table-cell-fix-right-last::after {
	position: absolute;
	top: 0;
	bottom: -1px;
	left: 0;
	width: 30px;
	transform: translateX(-100%);
	transition: box-shadow 0.3s;
	content: '';
	pointer-events: none;
  }
  .dark-bg .ant-table .ant-table-container::before,
  .dark-bg .ant-table .ant-table-container::after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	width: 30px;
	transition: box-shadow 0.3s;
	content: '';
	pointer-events: none;
  }
  .dark-bg .ant-table .ant-table-container::before {
	left: 0;
  }
  .dark-bg .ant-table .ant-table-container::after {
	right: 0;
  }
  .dark-bg .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
	position: relative;
  }
  .dark-bg .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .dark-bg .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .dark-bg .ant-table-ping-left .ant-table-cell-fix-left-last::after {
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .dark-bg .ant-table-ping-left .ant-table-cell-fix-left-last::before {
	background-color: transparent !important;
  }
  .dark-bg .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
	position: relative;
  }
  .dark-bg .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .dark-bg .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .dark-bg .ant-table-ping-right .ant-table-cell-fix-right-last::after {
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .dark-bg .ant-table-sticky-holder {
	position: -webkit-sticky;
	position: sticky;
	z-index: calc(3);
	background: #141414;
  }
  .dark-bg .ant-table-sticky-scroll {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	z-index: calc(3);
	display: flex;
	align-items: center;
	background: #fcfcfc;
	border-top: 1px solid #303030;
	opacity: 0.6;
  }
  .dark-bg .ant-table-sticky-scroll:hover {
	transform-origin: center bottom;
  }
  .dark-bg .ant-table-sticky-scroll-bar {
	height: 8px;
	background-color: rgba(0, 0, 0, 0.35);
	border-radius: 4px;
  }
  .dark-bg .ant-table-sticky-scroll-bar:hover {
	background-color: rgba(0, 0, 0, 0.8);
  }
  .dark-bg .ant-table-sticky-scroll-bar-active {
	background-color: rgba(0, 0, 0, 0.8);
  }
  @media all and (-ms-high-contrast: none) {
	.dark-bg .ant-table-ping-left .ant-table-cell-fix-left-last::after {
	  box-shadow: none !important;
	}
	.dark-bg .ant-table-ping-right .ant-table-cell-fix-right-first::after {
	  box-shadow: none !important;
	}
  }
  .dark-bg .ant-table {
	/* title + table */
	/* table */
	/* table + footer */
  }
  .dark-bg .ant-table-title {
	border-radius: 2px 2px 0 0;
  }
  .dark-bg .ant-table-title + .ant-table-container {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
  }
  .dark-bg .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
	border-radius: 0;
  }
  .dark-bg .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
	border-radius: 0;
  }
  .dark-bg .ant-table-container {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
  }
  .dark-bg .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 2px;
  }
  .dark-bg .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 2px;
  }
  .dark-bg .ant-table-footer {
	border-radius: 0 0 2px 2px;
  }
  .dark-bg .ant-table-wrapper-rtl {
	direction: rtl;
  }
  .dark-bg .ant-table-rtl {
	direction: rtl;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table table {
	text-align: right;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
	text-align: center;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	right: auto;
	left: 0;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-thead > tr > th {
	text-align: right;
  }
  .dark-bg .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
	margin: -16px 33px -16px -16px;
  }
  .dark-bg .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
	justify-content: flex-end;
  }
  .dark-bg .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
	justify-content: flex-start;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-column-sorter {
	margin-right: 4px;
	margin-left: 0;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-filter-column-title {
	padding: 16px 16px 16px 2.3em;
  }
  .dark-bg .ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
	padding: 0 0 0 2.3em;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-filter-trigger {
	margin: -4px 4px -4px -8px;
  }
  .dark-bg .ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .dark-bg .ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
  .dark-bg .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .dark-bg .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
	padding-right: 8px;
	padding-left: 0;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-selection {
	text-align: center;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-row-indent {
	float: right;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-row-expand-icon {
	float: right;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
	margin-right: 0;
	margin-left: 8px;
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
	transform: rotate(-90deg);
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
	transform: rotate(180deg);
  }
  .dark-bg .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
	transform: rotate(0deg);
  }
  .dark-bg .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
	border: 0;
	border-radius: 0;
  }
  .dark-bg .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
	width: 40px;
	min-width: 40px;
  }
  .dark-bg .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
	background: #1d1d1d;
  }
  .dark-bg .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
	margin: 0;
  }
  .dark-bg .ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
	margin: 16px 0 4px;
  }
  .dark-bg .ant-card {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(255, 255, 255, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	background: #141414;
	border-radius: 2px;
  }
  .dark-bg .ant-card-rtl {
	direction: rtl;
  }
  .dark-bg .ant-card-hoverable {
	cursor: pointer;
	transition: box-shadow 0.3s, border-color 0.3s;
  }
  .dark-bg .ant-card-hoverable:hover {
	border-color: transparent;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);
  }
  .dark-bg .ant-card-bordered {
	border: 1px solid #303030;
  }
  .dark-bg .ant-card-head {
	min-height: 48px;
	margin-bottom: -1px;
	padding: 0 24px;
	color: rgba(255, 255, 255, 0.85);
	font-weight: 500;
	font-size: 16px;
	background: transparent;
	border-bottom: 1px solid #303030;
	border-radius: 2px 2px 0 0;
  }
  .dark-bg .ant-card-head::before {
	display: table;
	content: '';
  }
  .dark-bg .ant-card-head::after {
	display: table;
	clear: both;
	content: '';
  }
  .dark-bg .ant-card-head-wrapper {
	display: flex;
	align-items: center;
  }
  .dark-bg .ant-card-head-title {
	display: inline-block;
	flex: 1;
	padding: 16px 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  }
  .dark-bg .ant-card-head-title > .ant-typography,
  .dark-bg .ant-card-head-title > .ant-typography-edit-content {
	left: 0;
	margin-top: 0;
	margin-bottom: 0;
  }
  .dark-bg .ant-card-head .ant-tabs-top {
	clear: both;
	margin-bottom: -17px;
	color: rgba(255, 255, 255, 0.85);
	font-weight: normal;
	font-size: 14px;
  }
  .dark-bg .ant-card-head .ant-tabs-top-bar {
	border-bottom: 1px solid #303030;
  }
  .dark-bg .ant-card-extra {
	float: right;
	margin-left: auto;
	padding: 16px 0;
	color: rgba(255, 255, 255, 0.85);
	font-weight: normal;
	font-size: 14px;
  }
  .dark-bg .ant-card-rtl .ant-card-extra {
	margin-right: auto;
	margin-left: 0;
  }
  .dark-bg .ant-card-body {
	padding: 24px;
  }
  .dark-bg .ant-card-body::before {
	display: table;
	content: '';
  }
  .dark-bg .ant-card-body::after {
	display: table;
	clear: both;
	content: '';
  }
  .dark-bg .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
	margin: -1px 0 0 -1px;
	padding: 0;
  }
  .dark-bg .ant-card-grid {
	float: left;
	width: 33.33%;
	padding: 24px;
	border: 0;
	border-radius: 0;
	box-shadow: 1px 0 0 0 #303030, 0 1px 0 0 #303030, 1px 1px 0 0 #303030, 1px 0 0 0 #303030 inset,
			  0 1px 0 0 #303030 inset;
	transition: all 0.3s;
  }
  .dark-bg .ant-card-rtl .ant-card-grid {
	float: right;
  }
  .dark-bg .ant-card-grid-hoverable:hover {
	position: relative;
	z-index: 1;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);
  }
  .dark-bg .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
	min-height: 32px;
	padding-bottom: 0;
  }
  .dark-bg .ant-card-contain-tabs > .ant-card-head .ant-card-extra {
	padding-bottom: 0;
  }
  .dark-bg .ant-card-bordered .ant-card-cover {
	margin-top: -1px;
	margin-right: -1px;
	margin-left: -1px;
  }
  .dark-bg .ant-card-cover > * {
	display: block;
	width: 100%;
  }
  .dark-bg .ant-card-cover img {
	border-radius: 2px 2px 0 0;
  }
  .dark-bg .ant-card-actions {
	margin: 0;
	padding: 0;
	list-style: none;
	background: #141414;
	border-top: 1px solid #303030;
  }
  .dark-bg .ant-card-actions::before {
	display: table;
	content: '';
  }
  .dark-bg .ant-card-actions::after {
	display: table;
	clear: both;
	content: '';
  }
  .dark-bg .ant-card-actions > li {
	float: left;
	margin: 12px 0;
	color: rgba(255, 255, 255, 0.45);
	text-align: center;
  }
  .dark-bg .ant-card-rtl .ant-card-actions > li {
	float: right;
  }
  .dark-bg .ant-card-actions > li > span {
	position: relative;
	display: block;
	min-width: 32px;
	font-size: 14px;
	line-height: 1.5715;
	cursor: pointer;
  }
  .dark-bg .ant-card-actions > li > span:hover {
	color: #177ddc;
	transition: color 0.3s;
  }
  .dark-bg .ant-card-actions > li > span a:not(.ant-btn),
  .dark-bg .ant-card-actions > li > span > .anticon {
	display: inline-block;
	width: 100%;
	color: rgba(255, 255, 255, 0.45);
	line-height: 22px;
	transition: color 0.3s;
  }
  .dark-bg .ant-card-actions > li > span a:not(.ant-btn):hover,
  .dark-bg .ant-card-actions > li > span > .anticon:hover {
	color: #177ddc;
  }
  .dark-bg .ant-card-actions > li > span > .anticon {
	font-size: 16px;
	line-height: 22px;
  }
  .dark-bg .ant-card-actions > li:not(:last-child) {
	border-right: 1px solid #303030;
  }
  .dark-bg .ant-card-rtl .ant-card-actions > li:not(:last-child) {
	border-right: none;
	border-left: 1px solid #303030;
  }
  .dark-bg .ant-card-type-inner .ant-card-head {
	padding: 0 24px;
	background: rgba(255, 255, 255, 0.04);
  }
  .dark-bg .ant-card-type-inner .ant-card-head-title {
	padding: 12px 0;
	font-size: 14px;
  }
  .dark-bg .ant-card-type-inner .ant-card-body {
	padding: 16px 24px;
  }
  .dark-bg .ant-card-type-inner .ant-card-extra {
	padding: 13.5px 0;
  }
  .dark-bg .ant-card-meta {
	margin: -4px 0;
  }
  .dark-bg .ant-card-meta::before {
	display: table;
	content: '';
  }
  .dark-bg .ant-card-meta::after {
	display: table;
	clear: both;
	content: '';
  }
  .dark-bg .ant-card-meta-avatar {
	float: left;
	padding-right: 16px;
  }
  .dark-bg .ant-card-rtl .ant-card-meta-avatar {
	float: right;
	padding-right: 0;
	padding-left: 16px;
  }
  .dark-bg .ant-card-meta-detail {
	overflow: hidden;
  }
  .dark-bg .ant-card-meta-detail > div:not(:last-child) {
	margin-bottom: 8px;
  }
  .dark-bg .ant-card-meta-title {
	overflow: hidden;
	color: rgba(255, 255, 255, 0.85);
	font-weight: 500;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
  }
  .dark-bg .ant-card-meta-description {
	color: rgba(255, 255, 255, 0.45);
  }
  .dark-bg .ant-card-loading {
	overflow: hidden;
  }
  .dark-bg .ant-card-loading .ant-card-body {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  .dark-bg .ant-card-loading-content p {
	margin: 0;
  }
  .dark-bg .ant-card-loading-block {
	height: 14px;
	margin: 4px 0;
	background: linear-gradient(90deg, rgba(48, 48, 48, 0.2), rgba(48, 48, 48, 0.4), rgba(48, 48, 48, 0.2));
	background-size: 600% 600%;
	border-radius: 2px;
	-webkit-animation: card-loading 1.4s ease infinite;
	animation: card-loading 1.4s ease infinite;
  }
  @-webkit-keyframes card-loading {
	0%,
	100% {
	  background-position: 0 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
  }
  @keyframes card-loading {
	0%,
	100% {
	  background-position: 0 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
  }
  .dark-bg .ant-card-small > .ant-card-head {
	min-height: 36px;
	padding: 0 12px;
	font-size: 14px;
  }
  .dark-bg .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
	padding: 8px 0;
  }
  .dark-bg .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
	padding: 8px 0;
	font-size: 14px;
  }
  .dark-bg .ant-card-small > .ant-card-body {
	padding: 12px;
  }
  .dark-bg .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
	padding: 8px 0;
	font-size: 14px;
  }
  .dark-bg .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
	padding: 16px 0;
	font-size: 16px;
  }
  .dark-bg .ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
	padding: 6px 16px;
  }
  .dark-bg .ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
	padding: 7px 16px 6px;
  }
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin-right: 2px;
	margin-left: 0;
  }
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
  .dark-bg .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
	margin-right: 2px;
	margin-left: 0;
  }
  .dark-bg .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .dark-bg .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
	margin: 0;
	padding: 8px 16px;
	background: rgba(255, 255, 255, 0.04);
	border: 1px solid #303030;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .dark-bg .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .dark-bg .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
	color: #177ddc;
	background: #141414;
  }
  .dark-bg .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
  .dark-bg .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
	visibility: hidden;
  }
  .dark-bg .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin-left: 2px;
  }
  .dark-bg .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 2px 2px 0 0;
  }
  .dark-bg .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .dark-bg .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-bottom-color: #141414;
  }
  .dark-bg .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 0 2px 2px;
  }
  .dark-bg .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
  .dark-bg .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-top-color: #141414;
  }
  .dark-bg .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin-top: 2px;
  }
  .dark-bg .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 2px 0 0 2px;
  }
  .dark-bg .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
  .dark-bg .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-right-color: #141414;
  }
  .dark-bg .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .dark-bg .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 2px 2px 0;
  }
  .dark-bg .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
  .dark-bg .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-left-color: #141414;
  }
  .dark-bg .ant-drawer .ant-card {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-drawer .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
	background-color: #1f1f1f;
	border-bottom: 1px solid #1f1f1f;
  }
  .dark-bg .ant-list .ant-card {
	background: transparent;
  }
  .dark-bg .ant-modal .ant-card {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-modal .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
	background-color: #1f1f1f;
	border-bottom: 1px solid #1f1f1f;
  }
  .dark-bg .ant-notification .ant-card {
	background-color: #1f1f1f;
  }
  .dark-bg .ant-notification .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
	background-color: #1f1f1f;
	border-bottom: 1px solid #1f1f1f;
  }
  .dark-bg .ant-upload.ant-upload-select-picture-card {
	width: 104px;
	height: 104px;
	margin-right: 8px;
	margin-bottom: 8px;
	text-align: center;
	vertical-align: top;
	background-color: rgba(255, 255, 255, 0.04);
	border: 1px dashed #434343;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
  }
  .dark-bg .ant-upload.ant-upload-select-picture-card > .ant-upload {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
  }
  .dark-bg .ant-upload.ant-upload-select-picture-card:hover {
	border-color: #177ddc;
  }
  .dark-bg .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
	border-color: #434343;
  }
  .dark-bg .ant-upload-picture-card-wrapper {
	display: inline-block;
	width: 100%;
  }
  .dark-bg .ant-upload-picture-card-wrapper::before {
	display: table;
	content: '';
  }
  .dark-bg .ant-upload-picture-card-wrapper::after {
	display: table;
	clear: both;
	content: '';
  }
  .dark-bg .ant-upload-list-item-card-actions {
	position: absolute;
	right: 0;
  }
  .dark-bg .ant-upload-list-item-card-actions-btn {
	opacity: 0;
  }
  .dark-bg .ant-upload-list-item-card-actions-btn.ant-btn-sm {
	height: 20px;
	line-height: 1;
  }
  .dark-bg .ant-upload-list-item-card-actions.picture {
	top: 22px;
	line-height: 0;
  }
  .dark-bg .ant-upload-list-item-card-actions-btn:focus,
  .dark-bg .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
	opacity: 1;
  }
  .dark-bg .ant-upload-list-item-card-actions .anticon {
	color: rgba(255, 255, 255, 0.45);
  }
  .dark-bg .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
	opacity: 1;
  }
  .dark-bg .ant-upload-list-item-error,
  .dark-bg .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
  .dark-bg .ant-upload-list-item-error .ant-upload-list-item-name {
	color: #a61d24;
  }
  .dark-bg .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
	color: #a61d24;
  }
  .dark-bg .ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
	opacity: 1;
  }
  .dark-bg .ant-upload-list-item-progress {
	position: absolute;
	bottom: -12px;
	width: 100%;
	padding-left: 26px;
	font-size: 14px;
	line-height: 0;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item {
	position: relative;
	height: 66px;
	padding: 8px;
	border: 1px solid #434343;
	border-radius: 2px;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item:hover,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item:hover {
	background: transparent;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-error,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-error {
	border-color: #a61d24;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-info,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-info {
	padding: 0;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
	background: transparent;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-uploading,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading {
	border-style: dashed;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	width: 48px;
	height: 48px;
	line-height: 60px;
	text-align: center;
	opacity: 0.8;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
	font-size: 26px;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
	fill: #e6d9d8;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
	fill: #a61d24;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-icon,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 26px;
	transform: translate(-50%, -50%);
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-icon .anticon,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
	font-size: 26px;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-image,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-image {
	max-width: 100%;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	display: block;
	width: 48px;
	height: 48px;
	overflow: hidden;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-name,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-name {
	display: inline-block;
	box-sizing: border-box;
	max-width: 100%;
	margin: 0 0 0 8px;
	padding-right: 8px;
	padding-left: 48px;
	overflow: hidden;
	line-height: 44px;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.3s;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
	margin-bottom: 12px;
  }
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-progress,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-progress {
	bottom: 14px;
	width: calc(76%);
	margin-top: 0;
	padding-left: 56px;
  }
  .dark-bg .ant-upload-list-picture .anticon-close,
  .dark-bg .ant-upload-list-picture-card .anticon-close {
	position: absolute;
	top: 8px;
	right: 8px;
	line-height: 1;
	opacity: 1;
  }
  .dark-bg .ant-upload-list-picture-card-container {
	display: inline-block;
	width: 104px;
	height: 104px;
	margin: 0 8px 8px 0;
	vertical-align: top;
  }
  .dark-bg .ant-upload-list-picture-card.ant-upload-list::after {
	display: none;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item {
	height: 100%;
	margin: 0;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-info {
	position: relative;
	height: 100%;
	overflow: hidden;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-info::before {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: all 0.3s;
	content: ' ';
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
	opacity: 1;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	white-space: nowrap;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all 0.3s;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
	z-index: 10;
	width: 16px;
	margin: 0 4px;
	color: rgba(255, 255, 255, 0.85);
	font-size: 16px;
	cursor: pointer;
	transition: all 0.3s;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
	color: #fff;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
	opacity: 1;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	position: static;
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-name {
	display: none;
	margin: 8px 0 0;
	padding: 0;
	line-height: 1.5715;
	text-align: center;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
	position: absolute;
	bottom: 10px;
	display: block;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
	background-color: rgba(255, 255, 255, 0.04);
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
	height: auto;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
	display: none;
  }
  .dark-bg .ant-upload-list-picture-card .ant-upload-list-item-progress {
	bottom: 32px;
	width: calc(86%);
	padding-left: 0;
  }
  .dark-bg .ant-upload-list-text-container,
  .dark-bg .ant-upload-list-picture-container {
	transition: opacity 0.3s, height 0.3s;
  }
  .dark-bg .ant-upload-list-text-container::before,
  .dark-bg .ant-upload-list-picture-container::before {
	display: table;
	width: 0;
	height: 0;
	content: '';
  }
  .dark-bg .ant-upload-list-text-container .ant-upload-span,
  .dark-bg .ant-upload-list-picture-container .ant-upload-span {
	display: block;
	flex: auto;
  }
  .dark-bg .ant-upload-list-text .ant-upload-span,
  .dark-bg .ant-upload-list-picture .ant-upload-span {
	display: flex;
	align-items: center;
  }
  .dark-bg .ant-upload-list-text .ant-upload-span > *,
  .dark-bg .ant-upload-list-picture .ant-upload-span > * {
	flex: none;
  }
  .dark-bg .ant-upload-list-text .ant-upload-list-item-name,
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-name {
	flex: auto;
	margin: 0;
	padding: 0 8px;
  }
  .dark-bg .ant-upload-list-text .ant-upload-list-item-card-actions,
  .dark-bg .ant-upload-list-picture .ant-upload-list-item-card-actions {
	position: static;
  }
  .dark-bg .ant-upload-list-text .ant-upload-text-icon .anticon {
	position: static;
  }
  
  .dark-bg .ant-empty-description{
	  color: #fff;
  }
  .dark-bg .ant-statistic-content-value-int{
	  color: #fff;
  }

.dark-bg {
	padding: 15px 15px;
	width: 100%;
	height: 100%;
	min-width: 940px;
	background: #141414;
}