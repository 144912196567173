.addTitle {
	font-weight: 600;
	font-size: 35px;
	margin-left: 20px;
}
.add-company-add-div {
	margin-top: 30px;
	width: 75%;
	display: flex;
}
.add-company-count-div1 {
	text-align: right;
	margin-top: 5px;
}
.add-company-count-div {
	width: 65%;
}
.form-control2 {
	display: inline-block;
	width: 30%;
	height: 32px;
	padding: 5px 8px;
	font-size: 13px;
	line-height: 1.53846154;
	color: #222;
	vertical-align: middle;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.form-control4 {
	display: inline-block;
	height: 32px;
	padding: 5px 8px;
	font-size: 13px;
	line-height: 1.53846154;
	color: #222;
	vertical-align: middle;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-right: 10px;
}

.form-control3 {
	margin: 0px 5px;
}
.lbsDiv {
	margin-top: 5px;
}
.table-fixed td,
.table-fixed th {
	overflow: visible;
	white-space: nowrap;
}
