.basic-layout-header {
	/* flex: 0 0 75px; */
	padding: 0 32px;
	min-width: 1340px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/*border-bottom: 1px solid #e9eaf2;*/
	box-sizing: border-box;
	background-color: #1B2553 !important;
}

.basic-layout-header .app-header-title-wrapper {
	display: flex;
	flex: 1;
	align-items: center;
	background-color: #1B2553 !important;
	color: white;
}
.basic-layout-header .app-header-slider-title {
	/*display: flex;*/
	/*justify-content: space-between;*/
	/*align-items: center;*/
	color: white;

}
.custom_header_menu {
	margin: 0px !important;
	padding: 0px !important;
}
.custom_header_menu > .ant-menu-item , .custom_header_menu > .ant-menu-submenu{
	padding: 0 20px !important;
	margin: 0px !important;

}
.custom_header_menu > .ant-menu-item:hover, .custom_header_menu > .ant-menu-submenu:hover, .custom_header_menu >.ant-menu-submenu>.ant-menu-submenu-active:hover {
	color: #fff !important;
	background-color: #2F3B73 !important;
	border-bottom: 0 !important;
	height: 45px !important;
	top: 0!important;

}
.custom_header_menu > .ant-menu-item-selected:hover, .custom_header_menu > .ant-menu-submenu>  .ant-menu-submenu:hover, .custom_header_menu> .ant-menu-submenu-active:hover{
	color: #fff !important;
	background-color: #2F3B73 !important;
	border-bottom: 0 !important;
	height: 45px !important;
	top: 0!important;
}
.custom_header_menu > .ant-menu-item-selected, .custom_header_menu >.ant-menu-submenu> .ant-menu-submenu-active{
	color: #fff !important;
	background-color: #2F3B73 !important;
	border-bottom: 0 !important;
	height: 45px !important;
	top: 0!important;
}
.menu-a:hover {
	color: rgba(24, 144, 255, 1) !important;
}
/*.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {*/
/*	color: #1890ff;*/
/*}*/
