.container1 {
	padding: 15px;
}
.row {
	display: flex;
	/* margin-bottom: 20px; */
}

.icon1 {
	width: 70px;
	height: 70px;
	padding: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	opacity: 1;
	border-radius: 10px;
	font-size: 14px;
	font-family: Microsoft YaHei UI;
	font-weight: 400;
	color: #ffffff;
	margin-right: 25px;
	margin-top: 10px;
	cursor: pointer;
}

.icon1 a {
	color: #ffffff;
}
.icon1 a:hover {
	color: #ffffff;
}

.title {
	font-size: 16px;
	font-family: Microsoft YaHei UI;
	color: #18263c;
	opacity: 1;
}

.box {
	padding: 16px 16px 16px 16px;
	background: #ffffff;
	/* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
	opacity: 1;
}

.box_item {
	display: flex;
	flex-wrap: wrap;
}

.weather {
	display: flex;
	align-items: flex-end;
	margin-top: 11px;
}

.date {
	display: flex;
	align-items: center;
	margin-top: 6px;
}

.time {
	width: 109px;
	height: 54px;
	font-size: 42px;
	font-family: Microsoft YaHei UI;
	font-weight: 400;
	line-height: 54px;
	color: #18263c;
	opacity: 1;
}

.yinli_date {
	margin-left: 19px;
}
.yinli_date div {
	width: 107px;
	height: 23px;
	font-size: 18px;
	font-family: Microsoft YaHei UI;
	font-weight: 400;
	line-height: 23px;
	color: #18263c;
	opacity: 1;
}

.yangli_date {
	margin-top: 16px;
	width: 198px;
	height: 23px;
	font-size: 18px;
	font-family: Microsoft YaHei UI;
	font-weight: 400;
	line-height: 23px;
	color: #18263c;
	opacity: 1;
}

.project_info {
}

.project_info .col1 {
	font-size: 14px;
	font-family: Microsoft YaHei UI;
	font-weight: 400;
	line-height: 18px;
	color: #777777;
	opacity: 1;
}

.project_info .col2 {
	font-size: 14px;
	font-family: Microsoft YaHei UI;
	font-weight: 400;
	line-height: 18px;
	color: #333333;
	opacity: 1;
}
