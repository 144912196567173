* {
	margin: 0;
	padding: 0;
}

#container {
	position: relative;
	/*margin-left: 20px;*/
	/*margin-top: 20px;*/
	border: 1px solid #ccc;
	overflow: hidden;
}

#map {
	/*position: absolute;*/
}
.svgStyle {
	display: inline-block;
	position: relative;
	padding-bottom: 100%;
	vertical-align: middle;
	overflow: hidden;
	width: 1000px;
	height: 800px;
}

.mark {
	position: absolute;
	width: 20px;
	height: 20px;
	font-size: 0px;
	background: url('../../../img/PointPosition.png') no-repeat left center;
	background-size: 100%;
}

.mark_input {
	position: absolute;
}

.content_p {
	position: absolute;
	height: 20px;
	line-height: 20px;
	margin-top: -7px;
	margin-left: 22px;
	padding: 3px 10px 23px 10px;
	border: 1px solid blue;
	background-color: #fff;
}
#clear {
	margin-left: 50px;
}
.PlanImg {
	height: 350px;
	width: 820px;
	padding: 10px;
	margin: 10px;
}
.plan-delete em {
	position: absolute;
	right: 10px;
}
.plan-looks {
	display: flex;
	width: 100%;
}
.plan-look {
	text-align: center;
}
.plan-body {
	text-align: center;
	max-height: 500px;
	overflow: scroll;
	padding: 10px;
	margin: 10px;
}
.choosePlan {
	margin: 10px;
	width: 450px;
	height: 300px;
}
.modal-cont {
	height: 640px !important;
}
.planbtn {
	text-align: center;
}
.disable {
	pointer-events: none;
	color: #bfbfbf;
}
.patrolbtn {
	margin: 10px auto;
	width: 1000px;
	position: relative;
	overflow: hidden;
}
